import { appProvider } from '@/app-provider'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { DaoRequest } from '@/models/dao-request-model'
import { ProfileModel } from '@/models/profile-model'
import { mostActiveDaoController } from '@/modules/common/components/section/most-active-dao-section-controller'
import { myDaoSectionController } from '@/modules/common/components/section/my-dao-section-controller'
import { apiService } from '@/services/api-services'
import { dispatcher } from '@/stores/dispatcher'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { map, orderBy } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class DaoDetailSettingViewModel {
  @observable dao?: CommunityModel = undefined
  @observable daoId?: string = undefined
  @observable isDaoMember = false

  /**
   * BLIND DIALOG STATUS
   */
  @observable blindDialog = false
  @observable daoMemberRequests: DaoRequest[] = []
  @observable daoMemberRequestFiltered: DaoRequest[] = []
  @observable daoMemberRequestSearchText = ''
  @observable daoMemberFetching = false
  @observable daoMemberRequestFetching = false
  @observable daoMembers: ProfileModel[] = []
  @observable blockedUsers: ProfileModel[] = []
  @observable openDaoRequestRejectDialog = false
  @observable openRejectReasonDialog = false
  @observable reasonForRejection = ''
  @observable selectedRequest: DaoRequest = {}
  @observable rejectLoading = false
  @observable searchMemberText = ''
  @observable reportedPosts: PostStore[] = []
  @observable fetchReportedPostLoading = false
  @observable updateBlindLoading = false
  @observable openFaqDialog = false
  @observable openRuleDialog = false
  @observable deleting = false
  @observable showDeleteDaoDialog = false

  private _disposers: IReactionDisposer[]
  private _unsubcrible = new Subject()

  constructor() {
    //
    this._disposers = [
      reaction(
        () => this.dao,
        async () => {
          this.searchMemberText = ''

          this.fetchDaoMembers()
          this.fetchDaoMemberRequests()
          this.fetchBlockedUsers()
        }
      ),
    ]

    dispatcher.$blindPostChanged.pipe(takeUntil(this._unsubcrible)).subscribe((obj) => {
      this.syncBlindReportedPosts(obj)
    })
  }

  @action setOpenDeleteDaoDialog(value) {
    this.showDeleteDaoDialog = value
  }

  @action changeOpenFaqDialog(value) {
    this.openFaqDialog = value
  }

  @action changeOpenRuleDialog(value) {
    this.openRuleDialog = value
  }

  @action syncBlindReportedPosts(obj) {
    const { postId, status } = obj
    const index = this.reportedPosts.findIndex((item) => item?.post?.id === postId)
    if (index === -1) return
    this.reportedPosts[index].post.isBlind = status
  }

  @action destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
  }

  @asyncAction *fetchReportedPost() {
    if (!this.daoId) return
    try {
      this.fetchReportedPostLoading = true
      const posts = yield apiService.daos.fetchReportedPosts(this.daoId)
      this.reportedPosts = posts?.map((item) => new PostStore(item))
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.fetchReportedPostLoading = false
    }
  }

  /**
   * REASON DIALOG STATUS
   */
  @observable reasonDialog = false
  @observable reason = ''

  @action.bound openReasonDialog() {
    this.reasonDialog = true
  }

  @action.bound closeReasonDialog() {
    this.reasonDialog = false
  }

  @action.bound summitReason() {
    this.closeReasonDialog()
  }

  @action.bound summitReasonReject() {
    this.closeReasonDialog()
  }

  @action.bound searchDaoMemberRequest() {
    if (this.daoMemberRequestSearchText) {
      const search = this.daoMemberRequests.filter((item) =>
        item.profile?.username?.includes(this.daoMemberRequestSearchText)
      )
      this.daoMemberRequestFiltered = search
    } else {
      this.daoMemberRequestFiltered = this.daoMemberRequests
    }
  }

  @action.bound setOpenDaoRequestRejectDialog(value: boolean) {
    this.openDaoRequestRejectDialog = value
  }

  @action.bound setOpenRejectReasonDialog(value: boolean) {
    this.openRejectReasonDialog = value
  }

  @action.bound changeReasonForRejection(value: string) {
    this.reasonForRejection = value
  }

  @action.bound clearReasonDialogState() {
    this.openRejectReasonDialog = false
    this.reasonForRejection = ''
  }

  @action.bound setSelectedRequest(item: DaoRequest) {
    this.selectedRequest = item
  }

  @asyncAction *loadData(id: string) {
    try {
      loadingController.increaseRequest()
      this.daoId = id

      this.dao = yield apiService.daos.fetchDao(id)
      if (!this.isOwnerDao && this.dao) {
        this.isDaoMember = yield apiService.daos.checkDaoMember({ daoId: this.daoId })
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *fetchDaoMembers() {
    try {
      if (this.daoId) {
        const userdao = yield apiService.userDao.find({
          dao: this.daoId,
          _limit: -1,
          _start: 0,
        })
        this.daoMembers = userdao.map((item) => ({ ...item.profile, role: item.role }))
      }
    } catch (e) {
      console.log('error fetchDaoMembers: ', e)
    }
  }

  @asyncAction *fetchBlockedUsers() {
    try {
      if (this.daoId) {
        const blockedUserDaos = yield apiService.userDao.find({
          dao: this.daoId,
          _limit: -1,
          _start: 0,
          status: 'blocked',
        })
        this.blockedUsers = map(blockedUserDaos, 'profile')
      }
    } catch (e) {
      console.log('error fetchDaoMembers: ', e)
    }
  }

  @asyncAction *fetchDaoMemberRequests() {
    try {
      this.daoMemberRequestFetching = true
      const daoMemberRequests = yield apiService.daoRequest.find({ status: 'pending', dao: this.daoId })
      this.daoMemberRequests = daoMemberRequests
      this.daoMemberRequestFiltered = daoMemberRequests
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.daoMemberRequestFetching = false
    }
  }

  @asyncAction *approveMemberRequest(item: DaoRequest) {
    try {
      item.approveLoading = true
      if (this.daoId && item.id) {
        const res = yield apiService.daos.approveMemberRequest(this.daoId, { requestId: item.id })
        if (res) snackController.success('Approve member request successfully')
        this.daoMemberRequestFiltered = this.daoMemberRequestFiltered.filter((request) => request.id !== item.id)
        this.daoMemberRequests = this.daoMemberRequests.filter((request) => request.id !== item.id)
      }
    } catch (error) {
    } finally {
      item.approveLoading = false
    }
  }

  @asyncAction *rejectMemberRequest(item: DaoRequest) {
    try {
      this.rejectLoading = true
      if (this.daoId && item.id) {
        const res = yield apiService.daos.rejectMemberRequest(this.daoId, {
          requestId: item.id,
          rejectReason: this.reasonForRejection,
        })
        this.daoMemberRequestFiltered = this.daoMemberRequestFiltered.filter((request) => request.id !== item.id)
        this.daoMemberRequests = this.daoMemberRequests.filter((request) => request.id !== item.id)
        this.clearReasonDialogState()
      }
    } catch (error) {
    } finally {
      this.rejectLoading = false
    }
  }

  @computed get validDaoMembers() {
    return this.daoMembers.filter((item) => item.username?.includes(this.searchMemberText))
  }
  @computed get validBlockedUsers() {
    return this.blockedUsers.filter((item) => item.username?.includes(this.searchMemberText))
  }

  @asyncAction *changeMemberRole(params) {
    const { member, role } = params
    if (!this.daoId || !member.id) return
    try {
      member.loading = true
      yield apiService.daos.updateMemberRole(this.daoId, { profile: member.id, role })
      member.role = role
      snackController.success(`${member.username} has been updated to a ${role}`)
    } catch (e) {
      console.log('Error changeMemberRole', e)
      snackController.commonError(e)
    } finally {
      member.loading = false
    }
  }
  @asyncAction *blockUserFromDao(user: ProfileModel) {
    if (!this.daoId || !user._id) return
    try {
      user.blockUserLoading = true
      yield apiService.daos.blockMember(this.daoId, { profile: user._id })
      snackController.success(`${user.username} has been removed from dao`)
      this.daoMembers = this.daoMembers.filter((item) => item._id !== user._id)
      this.blockedUsers.push(user)
    } catch (e) {
      console.log('Error blockUserFromDao', e)
      snackController.commonError(e)
    } finally {
      user.blockUserLoading = false
    }
  }
  @asyncAction *unBlockUserFromDao(user: ProfileModel) {
    if (!this.daoId || !user._id) return
    try {
      user.unBlockUserLoading = true
      yield apiService.daos.removeMember(this.daoId, { profile: user._id })
      snackController.success(`${user.username} has been unblocked from dao`)
      this.blockedUsers = this.blockedUsers.filter((item) => item._id !== user._id)
    } catch (e) {
      console.log('Error unBlockUserFromDao', e)
      snackController.commonError(e)
    } finally {
      user.unBlockUserLoading = false
    }
  }

  @asyncAction *deleteDao() {
    try {
      loadingController.increaseRequest()
      if (this.daoId) {
        const res = yield apiService.daos.deleteDao(this.daoId)
        yield myDaoSectionController.fetchData()
        yield mostActiveDaoController.fetchMostActiveDaos()
        if (res) snackController.success('Delete DAO successfully!')
        appProvider.router.push('/community/my-page')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @computed get owner() {
    return this.dao?.profile
  }

  @computed get isOwnerDao() {
    return this.owner && this.owner._id === walletStore.userProfile?._id
  }

  @computed get isContributorDao() {
    const contributors = this.dao?.contributors?.map((contributor) => contributor._id)
    return contributors?.includes(walletStore.userProfile?._id)
  }

  @computed get isAdmin() {
    return this.isOwnerDao || (this.isContributorDao && this.isDaoMember)
  }

  @computed get totalMemberRequest() {
    return this.daoMemberRequests.length || 0
  }

  @computed get filterContributors() {
    return this.validDaoMembers.filter((item) => item.role === 'contributor' || item.role === 'admin')
  }

  @computed get rules() {
    return this.dao?.rule?.list || []
  }
  @computed get faqs() {
    return this.dao?.faq?.list || []
  }
  @computed get sortedReportedPost() {
    return orderBy(this.reportedPosts, (post) => post.lastReportedTime || '', 'desc')
  }

  @computed get items() {
    if (!this.isAdmin) {
      return [
        { label: 'Members', icon: 'dao-management--members.svg', path: 'dao-members' },
        { label: 'Rules', icon: 'dao-management--rules.svg', path: 'dao-rules' },
        { label: 'FAQ', icon: 'dao-management--faqs.svg', path: 'dao-faqs' },
      ]
    } else {
      const menu = [
        { label: 'Member requests', icon: 'dao-management--members.svg', path: 'review-member-requests' },
        { label: 'Reported Posts', icon: 'review--reported-posts.svg', path: 'review-reported-posts' },
        { label: 'Members', icon: 'dao-management--members.svg', path: 'dao-members' },
        { label: 'Rules', icon: 'dao-management--rules.svg', path: 'dao-rules' },
        { label: 'FAQ', icon: 'dao-management--faqs.svg', path: 'dao-faqs' },
        { label: 'Settings', icon: 'dao-management--settings.svg', path: 'dao-settings' },
      ]
      if (this.isOwnerDao) {
        menu.push({ label: 'Delete', icon: 'delete-dao.svg', path: 'delete' })
      }
      return menu
    }
  }

  @computed get isRctDao() {
    return this.daoId == process.env.VUE_APP_RECRUITMENT_ID
  }
}
