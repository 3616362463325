




































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { DaoDetailSettingViewModel } from '../viewmodels/dao-detail-setting-viewmodel'

@Observer
@Component({
  components: {
    'navigation-drawer': () =>
      import('@/modules/community-organize/components/dao-detail-setting/components/navigation-drawer.vue'),

    'blind-post-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/blind-post-dialog.vue'),

    'reject-post-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/reject-post-dialog.vue'),

    'reason-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/reason-dialog.vue'),

    'reason-reject-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/reason-rejection-dialog.vue'),
    'check-before-deleting-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/check-before-deleting-dialog.vue'),
    'great-rules-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/great-rules-dialog.vue'),
    'great-faq-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/great-faq-dialog.vue'),
  },
})
export default class extends Vue {
  @Provide() setting = new DaoDetailSettingViewModel()
  @Watch('$route.params.id', { immediate: true }) async idChange(val) {
    if (val) await this.setting.loadData(val)
    // if (!this.setting.isAdmin) {
    //   this.$router.push(`/community-organize/dao-detail/${val}`)
    // }
  }

  destroyed() {
    this.setting.destroy()
  }
}
